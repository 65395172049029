#admin {  
    .institutions{
      list-style-type: none;
      padding-left: 0
    }
  
    .rows {
      width: 90%;
      margin-top: 1em;
      padding: 0.7em;
      justify-content: space-between;

      @media (max-width : 1000px) {
        padding: 3em 0.7em;
      }

      .flex-col {
        width: 10%;
      }

      .institutionName{
        padding: .5em 1em;
        font-weight: bold;
        width: 20%;
      }
    
      .institutionTotal, .policyAcknowledged{
        margin-top: .5em;
      }
    

      .fa {
        margin-left: .2em;
      }
      .data-number{
        margin-top: 0.5em;
      }

      .action{
        min-width: 8em;
      }
    }

    .label {
        color: white;
    }

    .rows:nth-child(odd) {
       background: white;

    }
  
    .rows:nth-child(even) {
        background: #f8f2ee;
    }

    .excel, .print{
      cursor: pointer;
    }

    .print{
      margin-left: 1em;
    }

    .MuiCircularProgress-colorPrimary.complete{
      color: @completed !important;
    }

    .complete-check-container{
      position: relative;
      color:  green;
    }

    .fa-check-circle{
        color:green;
    }

  }