#users {
    .addUserSection{
        margin-bottom: 3em;
    }

    .top-bar{
        justify-content: space-between;
        width: 100%;

        .account-selection{
            margin: 1em 0;
        }

        .selection{
            width: 20em;
            margin-right:1em;

            @media (max-width : 800px) {
                width: 15em;
            }
        }


        .actions{
            justify-content: flex-end;

            .flex-col{
                flex-grow: 0;
                padding: 1em;

                @media (max-width : 800px) {
                    width: 100%;
                }
            }
        }
    }

    .search-bar{
        justify-content: space-between;
        margin-bottom: 1em;
        padding-bottom: 1em;
    }

    .search-fields{
        justify-content: flex-end;

        @media (max-width : 1025px) {
            justify-content: flex-start;  
        }

    }

    .search-field {
        width: 30em;
        margin: .4em;

        @media (max-width : 1200px) {
            width: 15em;   
        }
    }

    .search-field.search-field-small{
        width: 10em;

        @media (max-width : 800px) {
            width: 3.5em;
        }
    }

    .building{
        margin-bottom: 2em;
        padding: 1em;
    }

    .buildingName{
        display: inline-block;
        margin-bottom: 0;
    }

    .buildingEditLink{
        margin-left: 0.2em;
        color: @dullcolor;
    }
    .buildingEditLink:hover {
        color: #d9d7db;
    }

    .buildingInfo{
        color: @dullcolor;
        font-size: .7em;
    }

    .fa-edit{
        font-size: .8em;
    }

    .table{
        display: table;
        width: 100%;
    }

    .row {
        display: table-row;
        margin: 1em;
    }
    
    .header{
        font-weight: bold;
        display: table-row;
    }

    .rows:nth-child(odd) {
        background: #f8f2ee;
    }

    .rows:nth-child(even) {
        background: white;
    }

      
    .col{
        display: table-cell;
        width: 10%;
        max-width: 20em;
        vertical-align: middle;
        text-align: center;

        @media (max-width : 1080px) {
            width: 20em;
        }
    }

    .institution-row{
        width: 60%;
        display: flex;
        justify-content: space-between;

        @media (max-width : 600px) {
            width: 100%;
        }

        .col{
            margin-right: 1em;
        }
    }

    .header-extra{
        display: inline;
    }

    .aggregateTrainingPercent{
        vertical-align: middle;
    }

    .MuiCircularProgress-colorPrimary.complete{
        color: @completed !important;
    }
    
    .menu-holder{
        position: relative;
    }

    .emailEdit{
        width: 15em;
    }

    .firstNameEdit .lastNameEdit {
        width: 7em;
    }

    .firstNameEdit {
        margin-right: 1em;
    }

    .fa-check {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .multipleAccess{
        .fa-check {
            display: inline-block;
            width: auto;
            margin-right: .1em;
            text-align: center;
        }
    }

    .fa-xmark{
        padding: 0.5em 1em;
        margin-right: auto;
        margin-left: auto;
        cursor: pointer;
    }

    .fa-save{
        cursor: pointer;
    }

    .menu-holder{
        .menu {
            position: relative;
            top: 0;
            left: 0;
            font-size: .7em;

            .opener{
                z-index: 0;
            }

            .menu-items{
                position: absolute;
                top: -1em;
                left: -5em;  
                z-index: 99;  
                width: 10em;
            }
        }
    }

    .top-menu{
        position: relative;
        width: 2em;
        text-align: right;

        .menu {
            z-index: 100;
            top: 1.5em;
            right: 0;
            left: auto;

            @media (max-width : 800px) {
                top: -13em;
            }
            
        }

        .menu-items{
            width: 15em;
        }


    }


}

.editedUser-dialog{
    padding: 0 2em 2em 2em;
  
    .editedUserEmail{
        min-width: 15em;
        margin-right: 1em;
    }

    .editedUserFirstName{
        margin-right: 1em;
    }
}