.addUser{
    .addUserSection{
        padding: 1em;
    }

    .userToAddEmail{
        width: 30em;
        @media (max-width : 800px) {
            width: 100%;
        }
    }

    .userToAddRole{
        width: 10em;
    }

    .addUserActions{
        width: 20em;
        justify-content: space-between;
 
        @media (max-width : 800px) {
           width: auto;
         }

        .flex-col{
            width: 8em;
        }

    }

    .typical-role{
        width: 10em;
    }
}