.helpToolTip{
    display: inherit;

    .helpicon {
        font-size: 0.6em;
        background-color: lightgrey;
        color: black;
        padding: 0.5em 1em;
        border-radius: 1.5em;
        margin-right: auto;
        margin-left: 1em;
        margin-top: 1em;
        cursor: pointer;
    }
}       