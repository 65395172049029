.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
.MuiButton-root:hover {
  color: white;
  background: #a90004 !important;
}
.MuiInput-root input[type="date"] {
  margin-top: 1em !important;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #a90004 !important;
}
button .material-icons {
  margin-right: 0.2em;
  padding-right: 0.2em;
}
.helpToolTip {
  display: inherit;
}
.helpToolTip .helpicon {
  font-size: 0.6em;
  background-color: lightgrey;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: 1em;
  margin-top: 1em;
  cursor: pointer;
}
header .logo {
  font-size: 2em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-top: 0.4em;
    margin-left: 2.5em;
  }
}
header .logo-red {
  color: #f8f2ee;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #f8f2ee;
  margin-left: 1em;
  margin-top: 0.7em;
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .link-back-login {
  padding-left: 2em;
  padding-top: 1.8em;
}
@media (max-width: 1000px) {
  header .link-back-login {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.1em;
  }
}
header .back-login {
  font-size: 2em;
  color: white;
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
    display: none;
  }
}
header .need-help:hover {
  color: #a90004;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
#cookie-policy .cookie-policy {
  position: fixed;
  bottom: 0;
  color: white;
  background: #989797;
  padding: 1em;
  z-index: 1;
}
#cookie-policy .cookie-policy-text {
  width: 88%;
}
#sidebar {
  background: #2f2e2e;
  height: 100%;
  min-height: 100em;
  top: 6em;
  position: absolute;
  border: 0px solid #000000;
}
#sidebar .module {
  white-space: pre-wrap;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: #cccccc;
  height: 4em;
  text-align: left;
  padding: 1em;
  width: 15em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
  text-decoration: none;
  cursor: pointer;
}
#sidebar .module a:link {
  color: #cccccc;
}
#sidebar .module a:hover {
  color: white;
}
#sidebar .module.active {
  background: #f8f2ee !important;
  color: black !important;
}
#sidebar .module.active a:hover {
  color: black;
}
#sidebar .linkStyle {
  width: 13em;
}
@media (max-width: 1000px) {
  #sidebar .linkStyle {
    padding-right: 2em;
  }
}
#sidebar .complete-check {
  position: absolute;
  color: green;
  left: 8em;
  bottom: 2.1em;
  font-size: 1.8em;
}
#sidebar div {
  white-space: pre-wrap;
}
#sidebar div a:link {
  color: white;
}
#sidebar div a:hover {
  color: white !important;
}
#sidebar .showNav-bar {
  position: absolute;
  top: 0.9em;
  left: 1.5em;
  font-size: 2em;
  color: white;
}
@media (max-width: 1000px) {
  #sidebar .showNav-bar {
    top: 0.6em;
  }
}
#sidebar .mobile-nav {
  position: absolute;
  top: 4em;
  background-color: black;
  z-index: 99;
}
#sidebar .desktop {
  display: block;
}
#sidebar .tablet {
  display: none;
}
#sidebar .tablet .btn {
  height: 7em;
  font-size: 14px;
  display: block;
}
#sidebar .tablet .complete-check {
  left: 8em;
  height: 1.2em;
}
@media (max-width: 1000px) {
  #sidebar .desktop {
    display: none;
  }
  #sidebar .tablet {
    display: block;
  }
}
@media (max-width: 1000px) {
  #sidebar {
    width: 0;
    top: 0;
  }
}
.menu .opener {
  font-size: 1.5em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
.menu .opener:hover {
  color: darkgray;
}
.menu .open {
  display: none;
}
.menu {
  position: relative;
  top: 0;
  left: 0;
  font-size: 0.7em;
}
.menu-item {
  font-size: 1.2em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  z-index: 100;
}
.menu-item:hover {
  color: darkgray;
}
.bulkAddUsers .bulkAdd {
  padding: 1em;
}
.bulkAddUsers .invite-choice {
  justify-content: space-between;
  width: 40%;
}
.bulkAddUsers .card-content {
  font-size: 1.5em;
}
.bulkAddUsers .userToAddRole {
  width: 10em;
}
.bulkAddUsers .instructions {
  margin-right: 4em;
}
.bulkAddUsers textarea {
  padding-bottom: 1em;
  width: 99%;
  margin-bottom: 1.5em;
  height: 10em;
}
.bulkAddUsers .dropzonearea {
  margin: 1em 0;
  width: 50%;
}
@media (max-width: 1000px) {
  .bulkAddUsers .dropzonearea {
    width: 100%;
  }
}
.bulkAddUsers .addUserActions {
  width: 20em;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .bulkAddUsers .addUserActions {
    width: 100%;
  }
}
.bulkAddUsers .addUserActions .flex-col {
  width: 8em;
}
.bulkAddUsers .typical-role {
  width: 10em;
}
#admin .institutions {
  list-style-type: none;
  padding-left: 0;
}
#admin .rows {
  width: 90%;
  margin-top: 1em;
  padding: 0.7em;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  #admin .rows {
    padding: 3em 0.7em;
  }
}
#admin .rows .flex-col {
  width: 10%;
}
#admin .rows .institutionName {
  padding: 0.5em 1em;
  font-weight: bold;
  width: 20%;
}
#admin .rows .institutionTotal,
#admin .rows .policyAcknowledged {
  margin-top: 0.5em;
}
#admin .rows .fa {
  margin-left: 0.2em;
}
#admin .rows .data-number {
  margin-top: 0.5em;
}
#admin .rows .action {
  min-width: 8em;
}
#admin .label {
  color: white;
}
#admin .rows:nth-child(odd) {
  background: white;
}
#admin .rows:nth-child(even) {
  background: #f8f2ee;
}
#admin .excel,
#admin .print {
  cursor: pointer;
}
#admin .print {
  margin-left: 1em;
}
#admin .MuiCircularProgress-colorPrimary.complete {
  color: #288028 !important;
}
#admin .complete-check-container {
  position: relative;
  color: green;
}
#admin .fa-check-circle {
  color: green;
}
#users .addUserSection {
  margin-bottom: 3em;
}
#users .top-bar {
  justify-content: space-between;
  width: 100%;
}
#users .top-bar .account-selection {
  margin: 1em 0;
}
#users .top-bar .selection {
  width: 20em;
  margin-right: 1em;
}
@media (max-width: 800px) {
  #users .top-bar .selection {
    width: 15em;
  }
}
#users .top-bar .actions {
  justify-content: flex-end;
}
#users .top-bar .actions .flex-col {
  flex-grow: 0;
  padding: 1em;
}
@media (max-width: 800px) {
  #users .top-bar .actions .flex-col {
    width: 100%;
  }
}
#users .search-bar {
  justify-content: space-between;
  margin-bottom: 1em;
  padding-bottom: 1em;
}
#users .search-fields {
  justify-content: flex-end;
}
@media (max-width: 1025px) {
  #users .search-fields {
    justify-content: flex-start;
  }
}
#users .search-field {
  width: 30em;
  margin: 0.4em;
}
@media (max-width: 1200px) {
  #users .search-field {
    width: 15em;
  }
}
#users .search-field.search-field-small {
  width: 10em;
}
@media (max-width: 800px) {
  #users .search-field.search-field-small {
    width: 3.5em;
  }
}
#users .building {
  margin-bottom: 2em;
  padding: 1em;
}
#users .buildingName {
  display: inline-block;
  margin-bottom: 0;
}
#users .buildingEditLink {
  margin-left: 0.2em;
  color: darkgray;
}
#users .buildingEditLink:hover {
  color: #d9d7db;
}
#users .buildingInfo {
  color: darkgray;
  font-size: 0.7em;
}
#users .fa-edit {
  font-size: 0.8em;
}
#users .table {
  display: table;
  width: 100%;
}
#users .row {
  display: table-row;
  margin: 1em;
}
#users .header {
  font-weight: bold;
  display: table-row;
}
#users .rows:nth-child(odd) {
  background: #f8f2ee;
}
#users .rows:nth-child(even) {
  background: white;
}
#users .col {
  display: table-cell;
  width: 10%;
  max-width: 20em;
  vertical-align: middle;
  text-align: center;
}
@media (max-width: 1080px) {
  #users .col {
    width: 20em;
  }
}
#users .institution-row {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  #users .institution-row {
    width: 100%;
  }
}
#users .institution-row .col {
  margin-right: 1em;
}
#users .header-extra {
  display: inline;
}
#users .aggregateTrainingPercent {
  vertical-align: middle;
}
#users .MuiCircularProgress-colorPrimary.complete {
  color: #288028 !important;
}
#users .menu-holder {
  position: relative;
}
#users .emailEdit {
  width: 15em;
}
#users .firstNameEdit .lastNameEdit {
  width: 7em;
}
#users .firstNameEdit {
  margin-right: 1em;
}
#users .fa-check {
  display: inline-block;
  width: 100%;
  text-align: center;
}
#users .multipleAccess .fa-check {
  display: inline-block;
  width: auto;
  margin-right: 0.1em;
  text-align: center;
}
#users .fa-xmark {
  padding: 0.5em 1em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
#users .fa-save {
  cursor: pointer;
}
#users .menu-holder .menu {
  position: relative;
  top: 0;
  left: 0;
  font-size: 0.7em;
}
#users .menu-holder .menu .opener {
  z-index: 0;
}
#users .menu-holder .menu .menu-items {
  position: absolute;
  top: -1em;
  left: -5em;
  z-index: 99;
  width: 10em;
}
#users .top-menu {
  position: relative;
  width: 2em;
  text-align: right;
}
#users .top-menu .menu {
  z-index: 100;
  top: 1.5em;
  right: 0;
  left: auto;
}
@media (max-width: 800px) {
  #users .top-menu .menu {
    top: -13em;
  }
}
#users .top-menu .menu-items {
  width: 15em;
}
.editedUser-dialog {
  padding: 0 2em 2em 2em;
}
.editedUser-dialog .editedUserEmail {
  min-width: 15em;
  margin-right: 1em;
}
.editedUser-dialog .editedUserFirstName {
  margin-right: 1em;
}
.addUser .addUserSection {
  padding: 1em;
}
.addUser .userToAddEmail {
  width: 30em;
}
@media (max-width: 800px) {
  .addUser .userToAddEmail {
    width: 100%;
  }
}
.addUser .userToAddRole {
  width: 10em;
}
.addUser .addUserActions {
  width: 20em;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .addUser .addUserActions {
    width: auto;
  }
}
.addUser .addUserActions .flex-col {
  width: 8em;
}
.addUser .typical-role {
  width: 10em;
}
#building-add-edit .back {
  margin-left: 1em;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31465b+0,2f4257+100 */
  background: #f5f5f5;
  /* Old browsers */
}
html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.content {
  min-height: 50em;
  padding-bottom: 5em;
  margin-left: 21em;
  margin-right: 2em;
  background: #f5f5f5;
  border: 0px solid #000000;
  height: 100%;
}
@media (max-width: 1000px) {
  .content {
    margin-left: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }
}
.single-message {
  margin: 1em;
}
.footerSection {
  width: 100%;
  background-color: #f5f5f5;
  color: black;
  font-size: 0.75em;
  height: 12em;
  margin-top: -12em;
}
.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}
.btn-primary {
  border-radius: 10px !important;
  background: #f8f2ee !important;
  color: black;
  width: 100%;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-style: none;
}
.btn-primary:hover {
  background: #3f3f3f;
}
.btn-holder {
  width: 100px;
  margin: auto;
}
.main-container {
  width: 100%;
  min-height: 50em;
  padding-left: 21em;
  padding-bottom: 5em;
  background: #f5f5f5;
  border: 0px solid #000000;
  padding-right: 3em;
}
@media (max-width: 1000px) {
  .main-container {
    margin-left: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }
}
.main-container.no-sidebar {
  padding-left: 1em;
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 3em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
}
p {
  padding: 0.25em 2em 1em 0em;
  color: black;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.btn-first {
  border-radius: 14px 0px 0px 0px !important;
  -moz-border-radius: 14px 0px 0px 0px !important;
  -webkit-border-radius: 14px 0px 0px 0px !important;
  border: 0px solid #000000;
}
.btn-active {
  background: #f8f2ee !important;
  color: #3f3f3f !important;
}
.initial-btn-active {
  background: #f8f2ee !important;
  color: #3f3f3f !important;
}
.btn {
  width: 275px;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: black;
  height: 100px;
  text-align: left;
  padding: 1em;
  padding-top: 1.25em;
  padding-left: 2em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
  background: #5a5a5a;
  /* Old browsers */
  background: -moz-linear-gradient(top, #5a5a5a 0%, #2f2e2e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5a5a5a 0%, #2f2e2e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5a5a5a 0%, #2f2e2e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5a5a5a', endColorstr='#2f2e2e', GradientType=0);
  /* IE6-9 */
}
.main-content {
  width: 100%;
  background: #f5f5f5;
  height: 100%;
  border-radius: 14px;
  border: 0px solid #000000;
  padding: 2em;
}
select.input-field {
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  width: 100%;
  color: black;
}
textarea.input-field {
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  padding: 1em 0 1em 0.5em;
  height: 1em;
  width: 100%;
  color: black;
}
input.input-field {
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  padding: 1em 0 1em 0.5em;
  height: 1em;
  width: 100%;
  color: black;
}
.btn-next,
.btn-back {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
}
.btn-next:hover,
.btn-back:hover,
.btn-logout:hover {
  text-decoration: none;
  color: white;
  background: #a90004;
}
.btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #f8f2ee;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  .btn-logout {
    display: none;
  }
}
.question {
  color: black;
  padding-top: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.complete-message {
  margin: auto;
  color: black;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.message-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 1em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 7em!important;
  text-align: center;
}
.complete-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 2em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 8em!important;
  text-align: center;
}
.message-holder {
  width: 260px;
  padding: 0;
  margin: auto;
}
.manager-nav {
  /* Change the link color to #111 (black) on hover */
}
.manager-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #2f3c48;
}
.manager-nav li {
  float: left;
}
.manager-nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.manager-nav li a:hover {
  background-color: #000;
}
.manager-nav .inviteActive {
  background: black;
}
.manager-nav .reportActive {
  background: black;
}
.manager-nav .createActive {
  background: black;
}
.manager-nav .listActive {
  background: black;
}
.successMessage {
  font-weight: bold;
  color: #f8f2ee;
}
.errorMessage {
  font-weight: bold;
  color: #a90004;
}
label {
  margin-right: 0.5em;
}
.section {
  margin-top: 1em;
}
.menu {
  position: absolute;
  top: 6.7em;
  right: 3em;
}
@media (max-width: 1000px) {
  .menu {
    right: 1em;
  }
}
.paper {
  margin-bottom: 1em;
  padding: 1em;
}
.paper h2 {
  margin-top: 0;
}
@media (max-width: 600px) {
  .hide-phone {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .hide-tablet {
    display: none !important;
  }
}
