@import "./color";
@import "./Material";
@import "./HelpToolTip";
@import "./Header";
@import "./Message";
@import "./AppError";
@import "./Loader";
@import "./CookiePolicy";
@import "./SideNav";
@import "./Menu";
@import "./MenuItem";
@import "./BulkAddUsers";
@import "./Admin";
@import "./Users";
@import "./AddUser";
@import "./BuildingAddEdit";

a {
  text-decoration: none;
}


a:hover {
  text-decoration: none !important;
}


html
{
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#31465b+0,2f4257+100 */
  background: @frontground; /* Old browsers */
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.flex {
  display: flex
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column
}

.flex-center {
  display: flex;
  justify-content: center;
}

.content {
  min-height: 50em;
  padding-bottom: 5em;
  margin-left: 21em;
  margin-right: 2em;
  background: @frontground;
  border: 0px solid #000000;
  height: 100%;

  @media (max-width : 1000px) {
    margin-left: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }

}

.single-message{
  margin: 1em;
}

.footerSection{
  width: 100%;
  background-color: @frontground;
  color: black;
  font-size: .75em;
  height: 12em;
  margin-top: -12em;
 }

.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}

.btn-primary {
  border-radius: 10px !important;
  background: @headlines !important;
  color: black;
  width: 100%;
  margin: auto;
  padding-top: .5em;
  padding-bottom: .5em;
  border-style: none;
}

.btn-primary:hover {
  background: @background;
}

.btn-holder {
  width: 100px;  
  margin: auto;
}


  .main-container {
    width: 100%;
    min-height: 50em;
    padding-left: 21em;
    padding-bottom: 5em;
    background: @frontground;
    border: 0px solid #000000;
    padding-right: 3em;

    @media (max-width : 1000px) {
      margin-left: 0;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 2em;
    }

  }

  .main-container.no-sidebar{
    padding-left: 1em;
  }

  h1 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
   font-size: 3em;
  }

  h2 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
  }

  p {
    padding: .25em 2em 1em 0em;
    color: @textcolor;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  .btn-first {
    border-radius: 14px 0px 0px 0px !important;
    -moz-border-radius: 14px 0px 0px 0px !important;
    -webkit-border-radius: 14px 0px 0px 0px !important;
    border: 0px solid #000000;
  }

  .btn-active {
    background: @headlines !important;
    color: @background !important;
  }

  .initial-btn-active {
    background: @headlines !important;
    color: @background !important;
  }

  .btn {
    width: 275px;
    border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    color: @textcolor;
    height: 100px;
    text-align: left;
    padding: 1em;
    padding-top: 1.25em;
    padding-left: 2em;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
    background: #5a5a5a; /* Old browsers */
    background: -moz-linear-gradient(top,  #5a5a5a 0%, #2f2e2e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #5a5a5a 0%,#2f2e2e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #5a5a5a 0%,#2f2e2e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a5a5a', endColorstr='#2f2e2e',GradientType=0 ); /* IE6-9 */

  }

  .main-content{

        width: 100%;
        background: @frontground;
        height: 100%;
        border-radius: 14px;
        border: 0px solid #000000;
        padding: 2em;
  }

  select.input-field{
    height: 3em;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: content-box;
    width: 100%;
    color: black;
  }

  textarea.input-field {
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: content-box;
    padding: 1em 0 1em .5em;
    height: 1em;
    width: 100%;
    color: black;
  }

  input.input-field {
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: content-box;
    padding: 1em 0 1em .5em;
    height: 1em;
    width: 100%;
    color: black;
  }

  .btn-next,.btn-back {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: black;
    margin: auto;
    width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
  }

  .btn-next:hover,.btn-back:hover,.btn-logout:hover{
    text-decoration: none;
    color: white;
    background: @buttonHoverBackground;
  }

  .btn-logout {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: black;
    margin: auto;
    width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
    background: @headlines;
    float: right;
    margin-top: 1.8em;
    margin-right: 3em;

    @media only screen and (max-width : 1000px) {
      display: none;
    }
  }


  .question {
    color: black;
    padding-top: 1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  .complete-message {
    margin: auto;
    color: @textcolor;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }

  .message-box {
    width: 325px;
    height: 80px !important;
    margin-top: 1em!important;
    margin: auto;
    padding-top: 1em;
    border-radius: 1%;
    background-color: @frontground!important;
    -webkit-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    -moz-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    padding-bottom: 7em!important;
    text-align: center;
  } 

  .complete-box {
    width: 325px;
    height: 80px !important;
    margin-top: 1em!important;
    margin: auto;
    padding-top: 2em;
    border-radius: 1%;
    background-color: @frontground!important;
    -webkit-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    -moz-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    padding-bottom: 8em!important;
    text-align: center;
  } 
  .message-holder {
    width: 260px;
    padding: 0;
    margin: auto;
  }

  .manager-nav {
    ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #2f3c48;
  }

  li {
      float: left;
  }

  li a {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }

  /* Change the link color to #111 (black) on hover */
  li a:hover {
      background-color: #000;
  }

  .inviteActive {
    background: black;
  }

  .reportActive {
    background: black;
  }

  .createActive {
    background: black;
  }

  .listActive {
    background: black;
  }
  

  }

  .successMessage{
    font-weight: bold;
    color: @accent;
  }

  .errorMessage{
    font-weight: bold;
    color: @wrongAnswerText;
  }

  label{
    margin-right: .5em;
  }

  .section {
    margin-top: 1em;
  }

  .menu{
    position: absolute;
    top: 6.7em;
    right: 3em;

    @media (max-width : 1000px) {
     right: 1em;
    }
  }

  .paper {
    margin-bottom: 1em;
    padding: 1em;

    h2{
      margin-top: 0;
    }
  }

  .hide-phone { 
    @media (max-width : 600px) {
      display: none !important;
    }
  }

  .hide-tablet { 
    @media (max-width : 1024px) {
      display: none !important;
    }
  }

  
