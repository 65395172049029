.MuiButton-outlinedPrimary{
    color: black !important;
}

.MuiButton-outlinedPrimary:hover{
    color: white !important;
}

.MuiFab-primary {
    background-color: @buttonHoverBackground !important;
  }

.MuiButton-root:hover {
    color: white;
    background: @buttonHoverBackground !important;
}

.MuiInput-root input[type="date"]{
    margin-top: 1em !important;
}

.MuiCircularProgress-colorPrimary{
    color: @buttonHoverBackground !important;;
}

.MuiCheckbox-colorPrimary.Mui-checked{
    color: @buttonHoverBackground !important;;
}

button{
    .material-icons{
        margin-right: .2em;
        padding-right: 0.2em;
    }
}