.bulkAddUsers{
    .bulkAdd{
        padding: 1em;
    }

    .invite-choice{
        justify-content: space-between;
        width: 40%;
    }

    .card-content{
        font-size: 1.5em;
    }

    .userToAddRole{
        width: 10em;
    }

    .instructions{
        margin-right: 4em;
    }
  

    textarea {
        padding-bottom: 1em;
        width: 99%;
        margin-bottom: 1.5em;
        height: 10em;
    }

    .dropzonearea{
        margin: 1em 0;
        width: 50%;

        @media (max-width : 1000px) {
            width: 100%;
        }
    }

    .addUserActions{
        width: 20em;
        justify-content: space-between;

        @media (max-width : 800px) {
            width: 100%;
        }

        .flex-col{
            width: 8em;
        }
    }

    .typical-role{
        width: 10em;
    }
}